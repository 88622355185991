import { createSlice } from '@reduxjs/toolkit'
import API from 'core/api'
import { clone } from 'lodash'
import createModel from 'utils/createModel'

const handleRequest = (state) => {
  state.isFetching = true
  state.errors = {}
}

const handleRequestFetched = (state) => {
  state.isFetching = false
  state.errors = {}
}

const handleError = (state, { payload }) => {
  state.isFetching = false
  state.errors = payload
}

const handleSetDocuments = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  if (state.page < payload.page) {
    state.data.push(...payload.documents)
  } else {
    state.data = payload.documents
  }
  state.totals = payload.totals
  state.totalCount = payload.count
  state.page = payload.page
}

const handleAddDocument = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data.push(payload)
}

const handleEditDocument = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = state.data.map((doc) => {
    if (doc.id === payload.id) return payload
    return doc
  })
}

const handleRemoveDocument = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = state.data.filter((doc) => doc.id !== payload)
}

const initialState = {
  isFetching: null,
  errors: {},
  data: [],
  totals: [],
  totalCount: 0,
  page: 0,
}
const documentsSlice = createSlice({
  name: 'documents',
  initialState: initialState,
  reducers: {
    requestIsFetching: handleRequest,
    requestIsFetched: handleRequestFetched,
    requestError: handleError,
    setDocuments: handleSetDocuments,
    addDocument: handleAddDocument,
    editDocument: handleEditDocument,
    removeDocument: handleRemoveDocument,
    clearState: () => initialState,
  },
})

export const {
  requestIsFetching,
  requestIsFetched,
  requestError,
  setDocuments,
  addDocument,
  editDocument,
  removeDocument,
  clearState,
} = documentsSlice.actions

const options = {
  items: [
    'All (default)',
    'Yesterday',
    'Last 7 days',
    'Last 30 days',
    'Year ago',
  ],
  values: [
    '',
    new Date().setDate(new Date().getDate() - 1),
    new Date().setDate(new Date().getDate() - 7),
    new Date().setMonth(new Date().getMonth() - 1),
    new Date().setFullYear(new Date().getFullYear() - 1),
  ],
}

const normalizeResponse = (response, page) => {
  const documents = createModel(response.data.documents, 'Document')
  if (!response.data.documents.length) {
    return {
      documents: documents,
      count: response.headers['x-total-count'],
      page: 0,
      totals: response.data.totals,
    }
  }

  return {
    documents: documents,
    count: response.headers['x-total-count'],
    page,
    totals: response.data.totals,
  }
}

export const getDocuments =
  (status, search, sortBy, sortOrder, filters, page = 0, archived = false) =>
  async (dispatch, getState) => {
    const token = getState().auth.token
    if ((filters || status || search || sortBy) && page === 1) {
      dispatch(requestIsFetching())
    }
    try {
      const selectedFilter =
        filters &&
        options.values.find((el, i) => i === options.items.indexOf(filters))

      const filter = selectedFilter && `&createdAtFrom=${selectedFilter}`
      const sort =
        sortBy && sortOrder ? `&sort=${sortBy}&order=${sortOrder}` : ''
      const res = await API.getDocuments(
        token,
        status,
        search,
        sort,
        filter,
        page,
        archived
      )
      const documents = normalizeResponse(res, page)
      dispatch(setDocuments(documents))
      return documents
    } catch (err) {
      dispatch(requestError(err.response.data))
    }
  }

export const getDocument = (id) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await API.getDocument(token, id)
    const document = createModel(res.data, 'Document')
    dispatch(requestIsFetched())
    return document
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const getDocumentFile = (id) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await API.getDocumentFile(token, id)
    const blob = new Blob([res.data], { type: 'application/pdf' })
    const objectUrl = window.URL.createObjectURL(blob)
    dispatch(requestIsFetched())
    return objectUrl
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const getDocumentLogs = (id) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await API.getDocumentLogs(token, id)
    const logs = createModel(res.data, 'DocumentLogs')
    return logs
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const createDocument = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    let formData = new FormData()
    formData.append('document', data)
    const res = await API.createDocument(formData, token)

    const document = createModel(res.data, 'Document')
    return Promise.resolve(document)
  } catch (err) {
    // dispatch(requestError(err))
    return Promise.reject(err.response.data)
  }
}

export const publishDocument = (id, data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await API.publishDocument(id, data, token)
    dispatch(requestIsFetched())
    return res
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const archiveDocument = (id) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await API.archiveDocument(id, token)
    const document = createModel(res.data, 'Document')
    return document
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const unArchiveDocument = (id) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await API.unarchiveDocument(id, token)
    const document = createModel(res.data, 'Document')
    return document
  } catch (err) {
    dispatch(requestError(err.response.data))
  }
}

export const rejectDocument = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await API.rejectDocument(data, token)
    const document = createModel(res.data, 'Document')
    return document
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const updateDocument = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await API.updateDocument(data, token)
    const document = createModel(res.data, 'Document')
    dispatch(editDocument(document))
    return document
  } catch (err) {
    dispatch(requestError(err))
    throw err.response.data
  }
}

export const deleteDocument = (documentId) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await API.deleteDocument(documentId, token)
    if (!res.data.success) throw new Error('Document delete failed')
    dispatch(removeDocument(documentId))
    return document
  } catch (err) {
    dispatch(requestError(err))
    throw err.response && err.response.data ? err.response.data : err
  }
}

export const signRequest = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  try {
    const res = await API.signRequest(data, token)
    return res
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const signDocument = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  try {
    const res = await API.signDocument(data, token)
    return res
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const resendParticipantInvitation =
  (data) => async (dispatch, getState) => {
    const token = getState().auth.token
    try {
      const res = await API.resendDocumentInvitation(
        data.document,
        data.user,
        token
      )
      return res
    } catch (err) {
      return Promise.reject(err.response.data)
    }
  }

export const approveDocument = (id) => async (dispatch, getState) => {
  const token = getState().auth.token
  try {
    const res = await API.approveDocument(id, token)
    return res
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const getDocumentAuditCheck =
  (id, token) => async (dispatch, getState) => {
    try {
      const res = await API.getDocumentAudit(id, token)
      const data = createModel(res.data, 'Document')
      return data
    } catch (err) {
      let error = err.response && err.response.data
      error = error ? (error.message ? error : { message: error }) : ''
      return Promise.reject(error)
    }
  }

export const clearDocumentsState = () => (dispatch) => {
  dispatch(clearState())
}

export default documentsSlice.reducer
