import { createSlice } from '@reduxjs/toolkit'
import API from 'core/api-guest'
import { clearState } from 'ducks/user'

const handleRequest = (state) => {
  state.isFetching = true
}

const handleError = (state, { payload }) => {
  state.isFetching = false
  state.errors = payload
  state.token = undefined
  state.tokenLevel = 0
  state.user = {} 
  state.invitationToken = undefined
}

const handleSuccess = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.token = payload.data.token
  state.tokenLevel = payload.data.tokenLevel
  state.isAuthenticated = payload.data.token && payload.data.tokenLevel === 5
  state.user = payload.data.user
  state.invitationToken = payload.invitationToken
}

const handleStoreReset = (state) => {
  state.isFetching = false
  state.errors = {}
  state.token = undefined
  state.tokenLevel = 0
  state.user = {} 
  state.invitationToken = undefined
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isFetching: null,
    errors: {},
    isAuthenticated: false,
    tokenLevel: 0,
    token: undefined,
    invitationToken: undefined,
    user: {},
  },
  reducers: {
    requestIsFetching: handleRequest,
    requestError: handleError,
    successLoginStep: handleSuccess,
    resetAuthStore: handleStoreReset,
  },
})

export const {
  requestIsFetching,
  requestError,
  successLoginStep,
  resetAuthStore
} = authSlice.actions

export default authSlice.reducer

// Login
export const login = (token) => async (dispatch) => {
  dispatch(requestIsFetching())
  try {
    const response = await API.login(token)
    dispatch(successLoginStep({ ...response, invitationToken: token }))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(clearState())
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}