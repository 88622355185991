import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import businessReducers from './store-business'
import commonReducer from './store-common'
import guestReducer from './store-guest'
import personalReducer from './store-personal'

let reducer
let persistor
if (process.env.REACT_APP_TENANT === 'BUSINESS') {
  reducer = businessReducers.persistedReducer
} else if (process.env.REACT_APP_TENANT === 'PERSONAL') {
  reducer = personalReducer.persistedReducer
} else if (process.env.REACT_APP_TENANT === 'COMMON') {
  reducer = commonReducer.persistedReducer
} else {
  reducer = guestReducer.persistedReducer
}

const middlewares = []
middlewares.push(thunk)

let store

// disable redux dev tools in production mode
if (process.env.NODE_ENV === 'development') {
  store = configureStore({
    reducer,
    middleware: middlewares,
    devTools: true
  })

  persistor = persistStore(store)
} else {
  const enhancers = []
  const sentryReduxEnhancer = Sentry.createReduxEnhancer()
  enhancers.push(sentryReduxEnhancer)

  store = configureStore({
    reducer,
    middleware: middlewares,
    devTools: false,
    enhancers
  })
  persistor = persistStore(store)
}

export default () => {
  return { store, persistor }
}
