import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

if (!process.env.REACT_APP_DEFAULT_LANG) {
  throw new Error(
    'You need to define the default language in REACT_APP_DEFAULT_LANG.'
  )
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next)
  // pass the i18n instance to react-i18next.
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: process.env.REACT_APP_DEFAULT_LANG,
    otherLanguages: ['en-US'],
    debug: false,
    ns: 'common',
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    keySeparator: false,
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    detection: {
      // order and from where user language should be detected
      order: ['cookie'],
      // keys or params to lookup language from
      lookupCookie: 'i18nextLng',
      // cache user language on
      caches: ['cookie'],
    },
  })

export default i18n
