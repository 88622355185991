import axios from 'axios'
import getCookie from 'utils/getCookie'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api`,
})

/**
 * In case user is not authenticated we should send the current user locale as a header parameter to the backend so the response be in the same language as users'.
 */
instance.interceptors.request.use((req) => {
  const persistedInfo = JSON.parse(localStorage.getItem('persist:root'))
  if (persistedInfo && persistedInfo.auth) {
    const authData = JSON.parse(persistedInfo.auth)
    if (!authData.isAuthenticated) {
      req.headers.locale = getCookie('i18nextLng')
    }
  }
  return req
})

instance.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result)
          resolve(Promise.reject(error))
        }

        reader.onerror = () => {
          reject(error)
        }

        reader.readAsText(error.response.data)
      })
    }

    return Promise.reject(error)
  }
)

const config = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
}

const SHARED_API = {
  loginStep1: (data) => {
    return instance.post('/auth/login-step-1', data)
  },
  loginStep2: (data, token) => {
    return instance.post('/auth/login-step-2', data, config(token))
  },
  getPermissions: (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    return instance.get('/permissions', config)
  },
  createAccount: ({ email, type, activationCode }) => {
    return instance.post('/auth/create-account', {
      email,
      type,
      activationCode,
    })
  },
  validateEmail: ({ code }, token) => {
    return instance.post('/auth/validate-email', { code }, config(token))
  },
  updatePhone: ({ phone }, token) => {
    return instance.post('/auth/update-phone', { phone }, config(token))
  },
  validatePhone: ({ code }, token) => {
    return instance.post('/auth/validate-phone', { code }, config(token))
  },
  completeAccount: (data, token) => {
    return instance.post('/auth/complete-account', data, config(token))
  },
  completeAccountInvitation: (data, token) => {
    return instance.post(
      `/auth/complete-account-invitation`,
      data,
      config(token)
    )
  },
  passwordResetRequest: (data) => {
    return instance.post('/auth/request-password-reset', data)
  },
  passwordResetStep1: (data, token) => {
    return instance.post('/auth/password-reset-step-1', data, config(token))
  },
  passwordResetStep2: (data, token) => {
    return instance.post('/auth/password-reset-step-2', data, config(token))
  },
  resendValidationSMS: (data, token) => {
    return instance.post('/auth/resend-validation-sms', data, config(token))
  },
  resendValidationEmail: (data, token) => {
    return instance.post('/auth/resend-validation-email', data, config(token))
  },
  sendTwofaFallbackSms: (data, token) => {
    return instance.post('/auth/send-twofa-fallback-sms', data, config(token))
  },
  getVerificationAuthToken: (token) => {
    return instance.get('/user-verification/auth-token', config(token))
  },
  completeUserVerification: (data, token) => {
    return instance.post('/user-verification/complete', data, config(token))
  },
  getUserInfo: (token) => {
    return instance.get('/profile', config(token))
  },
  updateUser: (data, token) => {
    return instance.post('/profile/update', data, config(token))
  },
  updatePersonalInfo: (data, token) => {
    return instance.post('/profile/update-personal-info', data, config(token))
  },
  uploadAvatar: (data, token) => {
    return instance.post('/profile/upload-avatar', data, config(token))
  },
  getDocuments: (token, status, search, sort, filter, page, archived) => {
    return instance.get(
      `/documents?perPage=15${status ? `&status=${status}` : ''}${
        search ? `&search=${search}` : ''
      }${sort}${filter}${page ? `&page=${page}` : ''}${
        archived ? `&archived=true` : ''
      }`,
      config(token)
    )
  },
  getDocument: (token, id) => {
    return instance.get(`/documents/${id}`, config(token))
  },
  getDocumentFile: (token, id) => {
    const options = config(token)
    options.responseType = 'blob'
    return instance.get(`/documents/${id}/file`, options)
  },
  getDocumentLogs: (token, id) => {
    return instance.get(`/documents/${id}/logs`, config(token))
  },
  createDocument: (data, token) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }
    return instance.post('/documents', data, headers)
  },
  publishDocument: (id, data, token) => {
    return instance.patch(`/documents/${id}/publish`, data, config(token))
  },
  updateDocument: (data, token) => {
    const updateProps = Object.keys(data).filter((key) => key !== 'id')
    const payload = updateProps.reduce((acc, key) => {
      acc[key] = data[key]
      return acc
    }, {})
    return instance.patch(`/documents/${data.id}`, payload, config(token))
  },
  archiveDocument: (id, token) => {
    return instance.patch(`/documents/${id}/archive`, [], config(token))
  },
  unarchiveDocument: (id, token) => {
    return instance.patch(`/documents/${id}/unarchive`, [], config(token))
  },
  rejectDocument: (data, token) => {
    return instance.patch(
      `/documents/${data.id}/reject`,
      { reason: data.reason },
      config(token)
    )
  },
  deleteDocument: (documentId, token) => {
    return instance.delete(`/documents/${documentId}`, config(token))
  },
  signRequest: (data, token) => {
    return instance.patch(
      `/documents/${data.id}/sign/request`,
      [],
      config(token)
    )
  },
  signDocument: (data, token) => {
    return instance.patch(
      `/documents/${data.id}/sign`,
      { signRequestCode: data.code },
      config(token)
    )
  },
  approveDocument: (data, token) => {
    return instance.patch(`/documents/${data}/approve`, [], config(token))
  },
  identityValidationStarted: (userId, token) => {
    return instance.post(
      `/users/${userId}/identity-validation-started`,
      [],
      config(token)
    )
  },
  getDocumentAudit: (documentId, token) => {
    return instance.get(`/documents/${documentId}/audit`, config(token))
  },
  request2FaMethod: (method, token) => {
    return instance.post(
      '/auth/request-2-fa-method',
      { method: method },
      config(token)
    )
  },
  confirm2FaMethod: (code, token) => {
    return instance.post('/auth/confirm-2-fa-method', code, config(token))
  },
  findRedirectUrl: (code, key) => {
    return instance.post('/find-redirect-url', code, key)
  },
  resendDocumentInvitation: (documentId, userId, token) => {
    return instance.patch(
      `/documents/${documentId}/resend-invitation/${userId}`,
      [],
      config(token)
    )
  },
}

export default SHARED_API
