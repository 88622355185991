import React, { Suspense, lazy } from 'react'

import { Ability, detectSubjectType } from '@casl/ability'
import { AbilityContext } from 'components/Can'
import ErrorFallback from 'components/ErrorFallback'
import Spinner from 'components/Spinner'
import StyledContainer from 'components/StyledContainer'
import WebSocketProvider from 'components/WebSocket'
import isPlainObject from 'lodash/isPlainObject'
import { ErrorBoundary } from 'react-error-boundary'
import { Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'

import GlobalStyles from './styles/globalStyles'
import { theme, personalTheme, businessTheme, guestTheme } from './styles/theme'
import 'react-toastify/dist/ReactToastify.min.css'

const Common =
  process.env.REACT_APP_TENANT === 'COMMON' && lazy(() => import('./Common'))

const Business =
  process.env.REACT_APP_TENANT === 'BUSINESS' &&
  lazy(() => import('./Business'))

const Personal =
  process.env.REACT_APP_TENANT === 'PERSONAL' &&
  lazy(() => import('./Personal'))

const Guest =
  process.env.REACT_APP_TENANT === 'GUEST' &&
  lazy(() => import('./Guest'))

const App = () => {
  // Note: ability should be applied to personal tenant as well when we add support for it.
  const ability = new Ability([], {
    /**
     * Custom type detection of subject instances when matching rules.
     * React state/redux/persist stores plain DTOs, therefore we need to define a custom type field
     * on the model instance itself. See utils/createModel.
     * Ref: https://casl.js.org/v4/en/guide/subject-type-detection#custom-subject-type-detection
     */
    detectSubjectType: (subject) => {
      if (subject && isPlainObject(subject) && subject.__typename) {
        return subject.__typename
      }

      return detectSubjectType(subject)
    },
  })

  return (
    <>
      <Normalize />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Switch>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {process.env.REACT_APP_TENANT === 'BUSINESS' && (
            <Suspense fallback={<Spinner />}>
              <ThemeProvider theme={businessTheme}>
                <AbilityContext.Provider value={ability}>
                  <WebSocketProvider>
                    <Route path='/' component={Business} />
                    <StyledContainer
                      position='bottom-right'
                      autoClose={8000}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                  </WebSocketProvider>
                </AbilityContext.Provider>
              </ThemeProvider>
            </Suspense>
          )}
          {process.env.REACT_APP_TENANT === 'PERSONAL' && (
            <Suspense fallback={<Spinner />}>
              <ThemeProvider theme={personalTheme}>
                <AbilityContext.Provider value={ability}>
                  <WebSocketProvider>
                    <Route path='/' component={Personal} />
                    <StyledContainer
                      position='bottom-right'
                      autoClose={8000}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                  </WebSocketProvider>
                </AbilityContext.Provider>
              </ThemeProvider>
            </Suspense>
          )}
          {process.env.REACT_APP_TENANT === 'GUEST' && (
            <Suspense fallback={<Spinner />}>
              <ThemeProvider theme={guestTheme}>
                <AbilityContext.Provider value={ability}>
                  <WebSocketProvider>
                    <Route path='/' component={Guest} />
                    <StyledContainer
                      position='bottom-right'
                      autoClose={8000}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                    </WebSocketProvider>
                </AbilityContext.Provider>
              </ThemeProvider>
            </Suspense>
          )}
          {process.env.REACT_APP_TENANT === 'COMMON' && (
            <Suspense fallback={<Spinner />}>
              <Route path='/' component={Common} />
            </Suspense>
          )}
        </ErrorBoundary>
        </Switch>
      </ThemeProvider>
    </>
  )
}

export default App
