import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

const StyledContainer = styled(ToastContainer).attrs({})`
  .Toastify__toast--default {
    background: ${(props) => props.theme.notificationBackground};

    .Toastify__close-button {
      color: ${(props) => props.theme.secondaryTextColor};
    }
    .Toastify__toast-body {
      font-size: 14px;
      color: ${(props) => props.theme.secondaryTextColor};
    }
    .Toastify__progress-bar {
      background: ${(props) => props.theme.secondaryTextColor};
    }
  }
`

export default StyledContainer
