import React from 'react'

import styled from 'styled-components'
import { layout, space } from 'styled-system'

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
`

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  display: flex;
  align-self: center;

  @keyframes sk-chase {
    100% {
      transform: rotate(360deg);
    }
  }
`

const SpinnerDot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;

  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    border-radius: 100%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both;
    background-color: ${(props) => props.theme.secondaryTextColor};
  }

  @keyframes sk-chase-dot {
    80%,
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4);
    }
    100%,
    0% {
      transform: scale(1);
    }
  }

  &:nth-child(1) {
    animation-delay: -1.1s;
  }
  &:nth-child(2) {
    animation-delay: -1s;
  }
  &:nth-child(3) {
    animation-delay: -0.9s;
  }
  &:nth-child(4) {
    animation-delay: -0.8s;
  }
  &:nth-child(5) {
    animation-delay: -0.7s;
  }
  &:nth-child(6) {
    animation-delay: -0.6s;
  }
  &:nth-child(1):before {
    animation-delay: -1.1s;
  }
  &:nth-child(2):before {
    animation-delay: -1s;
  }
  &:nth-child(3):before {
    animation-delay: -0.9s;
  }
  &:nth-child(4):before {
    animation-delay: -0.8s;
  }
  &:nth-child(5):before {
    animation-delay: -0.7s;
  }
  &:nth-child(6):before {
    animation-delay: -0.6s;
  }
`

export const SmallSpinner = styled.div.attrs(props => ({
  className: 'c-spinner'
}))`
  position:relative;
  text-indent: -9999em;
  width: 15px;
  height: 15px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  display: none;

  @-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg);}
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 13px;
    height: 13px;
    border-style: solid;
    border-color: ${(props) => props.color ? props.theme.colors[props.color] : props.theme.borderColor};
    border-top-color: transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
  }
  ${layout}
  ${space}
`

export default (props) => (
  <SpinnerWrapper>
    <Spinner>
      <SpinnerDot {...props} />
      <SpinnerDot {...props} />
      <SpinnerDot {...props} />
      <SpinnerDot {...props} />
      <SpinnerDot {...props} />
      <SpinnerDot {...props} />
    </Spinner>
  </SpinnerWrapper>
)
