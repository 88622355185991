import React, { useEffect } from 'react'

import { Flex } from 'components/Flex'
import { useTranslation } from 'react-i18next'

export default function ErrorFallback({ error }) {
    const { t } = useTranslation(['common'])

    // Handle failed lazy loading of a JS/CSS chunk.
    useEffect(() => {
        const chunkFailedMessage = /Loading\s*(CSS)?\s*chunk [\d]+ failed/;
        if (error && error.message && chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }

    }, [error]);

    return (
    <Flex flexDirection='column' alignItems='center'>
        <p>{t('Something went wrong')}</p>
        <pre>{error?.message}</pre>
    </Flex>
    );
}