import axios from 'axios'
import getCookie from 'utils/getCookie'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api`,
})

/**
 * In case user is not authenticated we should send the current user locale as a header parameter to the backend so the response be in the same language as users'.
 */
instance.interceptors.request.use((req) => {
  const persistedInfo = JSON.parse(localStorage.getItem('persist:root'))
  if (persistedInfo && persistedInfo.auth) {
    const authData = JSON.parse(persistedInfo.auth)
    if (!authData.isAuthenticated) {
      req.headers.locale = getCookie('i18nextLng')
    }
  }
  return req
})

instance.interceptors.response.use((res) => {
  return res
},
  error => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    };

    return Promise.reject(error);
  }
)

const config = (token) => {
  return {
    headers: {
      Authentication: token,
    },
  }
}

const GUEST_API = {
  login: (token) => {
    return instance.post('/auth/login', { token: token })
  },
  getPermissions: (token) => {
    const config = {
      headers: {
        Authorization: token,
      },
    }
    return instance.get('/permissions', config)
  },
  getUserInfo: (token) => {
    return instance.get('/profile', config(token))
  },
  getDocuments: (token, status, search, sort, filter, page, archived) => {
    return instance.get(
      `/documents?perPage=15${status ? `&status=${status}` : ''}${search ? `&search=${search}` : ''
      }${sort}${filter}${page ? `&page=${page}` : ''}${archived ? `&archived=true` : ''
      }`,
      config(token)
    )
  },
  getDocument: (token, id) => {
    return instance.get(`/documents/${id}`, config(token))
  },
  getDocumentFile: (token, id) => {
    const options = config(token)
    options.responseType = 'blob'
    return instance.get(`/documents/${id}/file`, options)
  },
  getDocumentLogs: (token, id) => {
    return instance.get(`/documents/${id}/logs`, config(token))
  },
  archiveDocument: (id, token) => {
    return instance.patch(`/documents/${id}/archive`, [], config(token))
  },
  unarchiveDocument: (id, token) => {
    return instance.patch(`/documents/${id}/unarchive`, [], config(token))
  },
  rejectDocument: (data, token) => {
    return instance.patch(
      `/documents/${data.id}/reject`,
      { reason: data.reason },
      config(token)
    )
  },
  deleteDocument: (documentId, token) => {
    return instance.delete(`/documents/${documentId}`, config(token))
  },
  signRequest: (data, token) => {
    return instance.patch(`/documents/${data}/sign/request`, [], config(token))
  },
  signDocument: (data, token) => {
    return instance.patch(
      `/documents/${data.id}/sign`,
      { signRequestCode: data.code },
      config(token)
    )
  },
  approveDocument: (data, token) => {
    return instance.patch(`/documents/${data}/approve`, [], config(token))
  },
  identityValidationStarted: (userId, token) => {
    return instance.post(`/users/${userId}/identity-validation-started`, [], config(token))
  },
  getDocumentAudit: (documentId, token) => {
    return instance.get(`/documents/${documentId}/audit`, config(token))
  }
}

export default GUEST_API
