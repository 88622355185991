import styled from 'styled-components'
import {
  flexbox,
  space,
  position,
  typography,
  color,
  layout,
  border,
  background,
} from 'styled-system'

export const Flex = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  ${space}
  ${color}
  ${flexbox}
  ${position}
  ${typography}
  ${layout}
  ${border}
  ${background}
`
