import { createSlice } from '@reduxjs/toolkit'
import BUSINESS_API from 'core/api-business'

const handleRequest = (state) => {
  state.isFetching = true
}

const handleError = (state, { payload }) => {
  state.isFetching = false
  state.errors = payload
}

const handleErrorMonthlyUsage = (state) => {
  state.isFetching = false
  state.data = { ...state.data, monthlyUsage: {}}
}

const handleSuccess = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = {...state.data, ...payload }
}

const handleSuccessUpdate = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = {...state.data}
}

const initialState = {
  isFetching: null,
  errors: {},
  data: {},
  totalCount: 0,
  page: 0,
}

const billingPlanSlice = createSlice({
  name: 'billingPlan',
  initialState: initialState,
  reducers: {
    requestIsFetching: handleRequest,
    requestError: handleError,
    requestErrorMonthlyUsage: handleErrorMonthlyUsage,
    requestSuccess: handleSuccess,
    reguestSuccessUpdate: handleSuccessUpdate,
    clearState: () => initialState
  },
})

export const {
  requestIsFetching,
  requestError,
  requestSuccess,
  reguestSuccessUpdate,
  requestErrorMonthlyUsage,
  clearState
} = billingPlanSlice.actions

export default billingPlanSlice.reducer

export const getMonthlyUsage = () => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.getMonthlyUsage(token)
    dispatch(requestSuccess( {monthlyUsage: response.data }))
  } catch (err) {
    dispatch(requestErrorMonthlyUsage(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const getBillingPlans = () => async (dispatch, getState) => {
    const token = getState().auth.token
    dispatch(requestIsFetching())
    try {
      const response = await BUSINESS_API.getBillingPlans(token)
      dispatch(requestSuccess( {billingPlans: response.data } ))
    } catch (err) {
      dispatch(requestError(err.response.data))
      return Promise.reject(err.response.data)
    }
}

export const changeBillingPlan = (planId) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    await BUSINESS_API.selectBillingPlan(token, planId)
    dispatch(reguestSuccessUpdate())
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const upgradeBillingPlan = (planId) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    await BUSINESS_API.upgradeBillingPlan(token, planId)
    dispatch(reguestSuccessUpdate())
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}